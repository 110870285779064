<template>
  <div class="app-image-cropper">
    <section v-if="showPreview" class="preview-area">
      <div class="cropped-image">
        <img
          v-if="cropImg"
          :src="cropImg"
          class="max-w-100"
          alt="Cropped Image"
        />
        <div v-else class="crop-placeholder"></div>
      </div>
    </section>
    <b-button
      v-if="!disabled"
      :disabled="disabled"
      :variant="isError ? 'danger' : 'primary'"
      :class="{ 'cursor-default': disabled }"
      @click.prevent="showFileChooser"
    >
      <i class="fas fa-file-upload"></i>
      {{ $t('label.chooseImage') }}
    </b-button>
    <div v-if="isError" class="invalid-field-message">
      {{ $t('fieldErrorMessage.requiredFieldDefault') }}
    </div>

    <b-modal
      v-model="show"
      centered
      hide-footer
      no-close-on-backdrop
      :title="$t('label.cropImage')"
      size="lg"
      body-class="app-image-cropper-modal__body"
      @close="close"
    >
      <div class="d-flex justify-content-center max-h-100 overflow-auto">
        <section class="cropper-area">
          <div class="img-cropper position-relative">
            <vue-cropper
              ref="cropper"
              :aspect-ratio="16 / 9"
              :src="imgSrc"
              class="vue-cropper"
            />

            <div class="toolbar">
              <template v-for="(item, index) in toolbarButtons">
                <button
                  :ref="item.ref"
                  :key="index"
                  v-b-tooltip.hover
                  :title="item.title"
                  @click.prevent="item.function"
                >
                  <span :class="item.class" class="d-inline-block"></span>
                </button>
              </template>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      class="d-none"
      @input="setImage"
    />
  </div>
</template>

<script>
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    name: 'AppImageCropper',
    components: {
      VueCropper
    },
    props: {
      showPreview: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      value: {},
      fileSizeLimit: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        imgSrc: '/media/dummy/dummy-uploading-image.png',
        cropImg: null,
        show: false,
        toolbarButtons: [],
        isError: false
      };
    },
    watch: {
      show() {
        if (this.show) {
          this.$nextTick(() => {
            this.initialToolbarButtons();
          });
        }
      }
    },
    methods: {
      checkIsImageEmpty() {
        let IsImageEmpty = false;

        if (!this.value) {
          this.isError = !this.cropImg;
          IsImageEmpty = !this.cropImg;
        }

        return IsImageEmpty;
      },
      close() {
        this.imgSrc = '/media/dummy/dummy-uploading-image.png';
      },
      initialToolbarButtons() {
        this.toolbarButtons = [
          {
            title: 'Zoom In',
            function: () => {
              this.zoom(0.2);
            },
            class: 'fas fa-search-plus'
          },
          {
            title: 'Zoom Out',
            function: () => {
              this.zoom(-0.2);
            },
            class: 'fas fa-search-minus'
          },
          {
            title: 'Rotate Left',
            function: () => {
              this.rotate(90);
            },
            class: 'ki ki-refresh'
          },
          {
            title: 'Rotate Right',
            function: () => {
              this.rotate(-90);
            },
            class: 'ki ki-round'
          },
          {
            ref: 'flipX',
            title: 'Flip Horizontal',
            function: () => {
              this.flipX();
            },
            class: 'fas fa-arrows-alt-h'
          },
          {
            ref: 'flipY',
            title: 'Flip Vertical',
            function: () => {
              this.flipY();
            },
            class: 'fas fa-arrows-alt-v'
          },
          {
            title: 'Reset',
            function: () => {
              this.reset();
            },
            class: 'ki ki-reload'
          },
          {
            title: 'Confirm',
            function: () => {
              this.cropImage();
            },
            class: 'fas fa-check'
          }
        ];
      },
      cropImage() {
        let image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        // get image data for post processing, e.g. upload or setting image src

        this.cropImg = image;
        this.$emit('input', image);
        this.show = false;
        this.imgSrc = '/media/dummy/dummy-uploading-image.png';
        this.isError = false;
      },
      flipX() {
        const dom = this.$refs.flipX[0];
        let scale = dom.getAttribute('data-scale');
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleX(scale);
        dom.setAttribute('data-scale', scale);
      },
      flipY() {
        const dom = this.$refs.flipY[0];
        let scale = dom.getAttribute('data-scale');
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleY(scale);
        dom.setAttribute('data-scale', scale);
      },
      move(offsetX, offsetY) {
        this.$refs.cropper.move(offsetX, offsetY);
      },
      reset() {
        this.$refs.cropper.reset();
      },
      rotate(deg) {
        this.$refs.cropper.rotate(deg);
      },
      setImage(e) {
        const file = e.target.files[0];
        var fileSizeLimit = this.fileSizeLimit;
        const isValidFileSize = () => {
          let isValid = true;

          if (fileSizeLimit) {
            if (file.size > fileSizeLimit) {
              isValid = false;
              alert(
                'Sorry, your file size has exceeded, please select another file.'
              );
            }
          }

          return isValid;
        };

        if (file) {
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
          }

          if (isValidFileSize()) {
            if (typeof FileReader === 'function') {
              const reader = new FileReader();
              reader.onload = (event) => {
                this.imgSrc = event.target.result;
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(event.target.result);
              };

              reader.readAsDataURL(file);

              this.show = true;
            } else {
              alert('Sorry, FileReader API not supported');
            }
          }
        }
      },
      showFileChooser() {
        this.$refs.input.value = null;
        this.$refs.input.click();
      },
      zoom(percent) {
        this.$refs.cropper.relativeZoom(percent);
      }
    }
  };
</script>

<style lang="scss">
  .vue-cropper {
    object-fit: cover;
    max-height: 500px;
  }
  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }

  .toolbar {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 1rem;
    color: #fff;
    height: 2rem;
    left: 50%;
    margin-left: -8rem;
    position: absolute;
    width: 16rem;
    z-index: 1;

    button {
      background-color: transparent;
      border-width: 0;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      font-size: 0.875rem;
      height: 100%;
      text-align: center;
      width: 2rem;
      &:hover {
        background-color: #0074d9;
        color: #fff;
      }
    }
  }

  .app-image-cropper-modal__body {
    background-color: #333;
  }

  .invalid-field-message {
    font-size: 0.9rem;
    font-weight: 400;
    color: #f64e60;
    width: 100%;
    margin-top: 0.25rem;
  }
</style>
